@import '../../../../scss/theme-bootstrap';

.gnav-offers-formatter {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  &--ticker-overflow {
    overflow: visible;
  }
  @if is_2020_nav_redesign() {
    background-color: $color-ivory;
    color: $color-text;
    height: auto;
    min-height: $offerbar-height;
    @include breakpoint($landscape-up) {
      background-color: $color-drak-black;
      color: $color-white;
      height: 40px;
      min-height: 40px;
    }
  } @else {
    background-color: $color-drak-black;
    color: $color-white;
    height: 40px;
    min-height: 40px;
  }
  @if is_cr20_desktop_nav_refresh() {
    background-color: $color-ivory;
    color: $color-cr18-black;
    height: 48px;
    min-height: 48px;
    @include breakpoint($landscape-up) {
      background-color: $color-ivory;
      color: $color-cr18-black;
      height: 32px;
      min-height: 32px;
    }
  } @else {
    background-color: $color-drak-black;
    color: $color-white;
  }
  @include breakpoint($landscape-up) {
    width: auto;
  }
  .gnav-offers-hidden & {
    display: none;
    height: 0;
    min-height: 0;
  }
  &__carousel {
    position: relative;
    text-align: center;
    width: 100%;
    @include breakpoint($landscape-up) {
      display: flex;
      justify-content: space-between;
    }
    @if is_cr20_desktop_nav_refresh() {
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-50%);
      top: 50%;
      @include breakpoint($landscape-up) {
        height: 32px;
      }
    }
    &-item {
      display: none;
      @if is_2020_nav_redesign() {
        max-width: 85%;
        margin: 4px 0;
        @include breakpoint($landscape-up) {
          max-width: 100%;
          margin: 0;
        }
      }
      .not-initialized &:first-of-type,
      &.item-active {
        display: inline-block;
        @include breakpoint($landscape-up) {
          display: block;
        }
      }
    }
    &-arrow {
      cursor: pointer;
      display: inline-block;
      top: 44%;
      transform: translateY(-44%);
      @include breakpoint($landscape-up) {
        transform: translateY(0);
      }
      @if is_cr20_desktop_nav_refresh() {
        top: 50%;
        transform: translateY(-50%);
      }
      .icon {
        height: auto;
        margin: 0 10px;
        @if is_2020_nav_redesign() {
          line-height: $offerbar-height;
          @include breakpoint($landscape-up) {
            line-height: 40px;
          }
        }
        @if is_cr20_desktop_nav_refresh() {
          font-size: 9px;
          @include breakpoint($landscape-up) {
            line-height: normal;
          }
        } @else {
          line-height: 40px;
        }
      }
      &--left {
        @if is_2020_nav_redesign() {
          left: 0.25em;
          @include breakpoint($landscape-up) {
            left: 0.5em;
          }
        } @else {
          left: 0.5em;
        }
        position: absolute;
        @include breakpoint($landscape-up) {
          position: initial;
        }
        .icon {
          @if is_2020_nav_redesign() {
            margin: 0 10px 0 6px;
            @include breakpoint($landscape-up) {
              margin: 0 10px;
            }
          }
          @if is_cr20_desktop_nav_refresh() {
            @include breakpoint($landscape-up) {
              @include swap_direction(margin, 0 25px 0 0);
            }
          } @else {
            margin: 0 10px;
          }
        }
      }
      &--right {
        @if is_2020_nav_redesign() {
          right: 0.25em;
          @include breakpoint($landscape-up) {
            right: 0.5em;
          }
        } @else {
          right: 0.5em;
        }
        position: absolute;
        @include breakpoint($landscape-up) {
          position: initial;
        }
        .icon {
          @if is_2020_nav_redesign() {
            margin: 0 6px 0 10px;
            @include breakpoint($landscape-up) {
              margin: 0 10px;
            }
          }
          @if is_cr20_desktop_nav_refresh() {
            @include breakpoint($landscape-up) {
              @include swap_direction(margin, 0 0 0 25px);
            }
          } @else {
            margin: 0 10px;
          }
        }
      }
    }
  }
  a {
    color: $color-white;
    @if is_2020_nav_redesign() {
      @include secondary-hover;
      text-decoration: underline;
      color: $color-black;
      @include breakpoint($landscape-up) {
        color: $color-white;
        text-decoration: none;
      }
    }
    @if is_cr20_desktop_nav_refresh() {
      @include breakpoint($landscape-up) {
        @include secondary-hover;
        text-decoration: underline;
        color: $color-black;
      }
    } @else {
      color: $color-white;
    }
  }
  &__ticker {
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    overflow: visible;
    &-inner {
      width: fit-content;
      display: flex;
      position: relative;
      height: 100%;
      &[data-clickable] {
        cursor: pointer;
      }
      .offers-group {
        height: 100%;
        display: flex;
        flex-direction: row;
        animation: animate 70s linear infinite;
        &:nth-child(2) {
          animation: animate2 70s linear infinite;
          animation-delay: -35s;
        }
      }
    }
    .gnav-offer {
      display: flex;
      align-items: center;
      padding: 0 50px;
      margin: 0;
      white-space: nowrap;
      height: 100%;
    }
  }
  @keyframes animate {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  @keyframes animate2 {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-200%);
    }
  }
}
